import * as React from "react"
import { useState, useEffect, useMemo } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLocation } from "@reach/router"
import { useRenkApiUri } from "src/hooks"

import * as styles from "src/scss/_shop.module.scss"
import * as checkoutStyles from "src/scss/_checkOut.module.scss"

export default function ConfirmationPage () {
  const [name, setName] = useState(""); // 名前
  const [nameKana, setNameKana] = useState(""); // お名前（カタカナ）
  const [company, setCompany] = useState(""); // 会社名
  const [companyKana, setCompanyKana] = useState(""); // 会社名（カタカナ）
  const [zip, setZip] = useState(""); // 郵便番号
  const [state, setState,] = useState(""); // 都道府県
  const [address1, setAddress1] = useState(""); // 郡市区町村番地
  const [address2, setAddress2] = useState(""); // アパート・マンション
  const [mail, setMail] = useState(""); // メールアドレス
  const [tel, setTel] = useState(""); // 電話番号
  const [contactTel, setContactTel] = useState(""); // 連絡先電話番号
  const [fax, setFax] = useState(""); // FAX番号
  const [message, setMessage] = useState(""); // 備考

  const [count, setCount] = useState(0); // 伝票プリンターセット合計個数
  const [printerTotalPrice, setPrinterTotalPrice] = useState(0); // 伝伝票プリンターセット合計金額
  const [count2, setCount2] = useState(0); // 伝票のみ合計個数
  const [denpyoTotalPrice, setDenpyoTotalPrice] = useState(0); // 伝票のみ合計金額
  const [subTotalPrice, setSubTotalPrice] = useState(0); // 全体合計金額
  const [totalPrice, setTotalPrice] = useState(0); // 全体合計金額

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { hostname } = useLocation()
  const apiURI = useRenkApiUri(hostname)
  const GATEWAY_URL = useMemo(() => `${apiURI}order_form`, [apiURI])

  useEffect(() => {
    const customerData = JSON.parse(localStorage.getItem("customerInfoObj"))
    const priceData = JSON.parse(localStorage.getItem("orderInfoObj"))

    if (customerData == null) {
      alert("データがありません。ページトップに移動します。")
      window.location.href = '/';
    } else {
      setName(customerData.name)
      setNameKana(customerData.nameKana)

      const checkCompany = customerData.company
      if (checkCompany !== null) {
        setCompany(checkCompany)
      } else {
        setCompany("")
      }

      const checkCompanyKana = customerData.companyKana
      if (checkCompanyKana !== null) {
        setCompanyKana(checkCompanyKana)
      } else {
        setCompanyKana("")
      }

      setZip(customerData.zip)
      setState(customerData.state)

      setAddress1(customerData.address1)

      const checkAddress2 = customerData.address2
      if (checkAddress2 !== null) {
        setAddress2(checkAddress2)
      } else {
        setAddress2("")
      }

      setMail(customerData.mail)
      setTel(customerData.tel)

      const checkContactTel = customerData.contactTel
      if (checkContactTel !== null) {
        setContactTel(checkContactTel)
      } else {
        setContactTel("")
      }

      const checkFax = customerData.fax
      if (checkFax !== null) {
        setFax(checkFax)
      } else {
        setFax("")
      }

      const checkMessage = customerData.message
      if (checkMessage !== null) {
        setMessage(checkMessage)
      } else {
        setMessage("")
      }

      setCount(priceData.printerQuantity.count)
      setCount2(priceData.denpyoQuantity.count2)

      const printerTotal = priceData.printerPrice.totalPrinterPrice.toLocaleString()
      setPrinterTotalPrice(printerTotal)

      const denpyoTotal = priceData.denpyoPrice.totalDenpyoPrice.toLocaleString()
      setDenpyoTotalPrice(denpyoTotal)

      if (priceData.totalPrice !== null) {
        const preSubTotal = priceData.totalPrice.toLocaleString()
        setSubTotalPrice(preSubTotal) // 小計
        setTotalPrice(preSubTotal) // 合計
      }
    }
  }, []);

  const order = async () => {
    const submitBtn = document.getElementById("submitBtn")
    submitBtn.disabled = true;
    document.getElementById("loading").style.display = "block"; // 送信中のくるくる表示

    const reCaptchaToken = await executeRecaptcha('ConfirmationPage'); // token取得

    const customerData = JSON.parse(localStorage.getItem("customerInfoObj"))

    const itemData = JSON.parse(localStorage.getItem("orderInfoObj"))

    const items = []
    if (itemData.denpyoQuantity.count2) {
      const denpyoData = {
        name: "インフォ・ザ・伝票",
        price: itemData.denpyoPrice.totalDenpyoPrice,
        qty: itemData.denpyoQuantity.count2
      }
      items.push(denpyoData)
    }
    if (itemData.printerQuantity.count) {
      const printerData = {
        name: "インフォ・ザ・伝票 ＆ PLQ-50S（プリンター）セット",
        price: itemData.printerPrice.totalPrinterPrice,
        qty: itemData.printerQuantity.count
      }
      items.push(printerData)
    }

    try {
      const result = await fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify({
          token: reCaptchaToken,
          sender: customerData,
          items: items
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const recaptchaResult = await result.json();

      if (recaptchaResult.status) {
        localStorage.removeItem("PrinterSetQuantity");
        localStorage.removeItem("denpyoOnlyQuantity");
        localStorage.removeItem("customerInfoObj");
        localStorage.removeItem("orderInfoObj");

        const orderNum = recaptchaResult.order_no
        const orderDate = recaptchaResult.order_date
        const parametor = "num=" + orderNum + "&date=" + orderDate;

        const encoded = btoa(encodeURIComponent(parametor));

        window.location.href = "/online-store/order-confirmation" + "?" + encoded;
        document.getElementById("loading").style.display = "none";
        submitBtn.disabled = false;
      } else {
        alert("注文内容の送信に失敗しました。お手数をおかけしますが、間をおいてもう一度お試しください。")
        document.getElementById("loading").style.display = "none";
        submitBtn.disabled = false;
      }
    } catch (error) {
      alert("注文内容の送信に失敗しました。お手数をおかけしますが、間をおいてもう一度お試しください。")
      document.getElementById("loading").style.display = "none";
      submitBtn.disabled = false;
    }
  }

  return (
    <Layout>
      <Seo title="ご注文内容確認" />
      <div className="pageWrapper">
        <h2 className={styles.shopPageTtl}>ご注文内容確認</h2>

        <p className={checkoutStyles.subTtl}>お客様情報</p>
        <div className={checkoutStyles.customerInfoCheck}>
          <table>
            <thead>
              <tr>
                <td>お名前</td>
                <td>{ name }</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>フリガナ</td>
                <td>{ nameKana }</td>
              </tr>
              <tr>
                <td>会社名</td>
                <td>{ company }</td>
              </tr>
              <tr>
                <td>フリガナ</td>
                <td>{ companyKana }</td>
              </tr>
              <tr>
                <td>郵便番号</td>
                <td>{ zip }</td>
              </tr>
              <tr>
                <td>都道府県</td>
                <td>{ state }</td>
              </tr>
              <tr>
                <td>市区町村番地</td>
                <td>{ address1 }</td>
              </tr>
              <tr>
                <td>アパート・マンション</td>
                <td>{ address2 }</td>
              </tr>
              <tr>
                <td>メールアドレス</td>
                <td>{ mail }</td>
              </tr>
              <tr>
                <td>電話番号</td>
                <td>{ tel }</td>
              </tr>
              <tr>
                <td>連絡先電話番号</td>
                <td>{ contactTel }</td>
              </tr>
              <tr>
                <td>FAX番号</td>
                <td>{ fax }</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>備考</td>
                <td>{ message }</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <p className={checkoutStyles.subTtl}>お客様情報</p>
        <div className={checkoutStyles.bankTable}>
          <table>
            <tbody>
              <tr>
                <td>支払種別</td>
                <td>銀行振込</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className={checkoutStyles.subTtl}>注文商品</p>
        <div className={checkoutStyles.checkCart}>
          <table>
            <thead>
              <tr>
                <td>商品名称</td>
                <td>単価</td>
                <td>数量</td>
                <td>金額（税込）</td>
              </tr>
            </thead>
            <tbody>
              {count2 !== 0 &&
                <tr>
                  <td>インフォ・ザ・伝票</td>
                  <td className={checkoutStyles.textRight}>13,750</td>
                  <td>
                    {count2}
                  </td>
                  <td className={checkoutStyles.textRight}>
                    {denpyoTotalPrice}
                  </td>
                </tr>
              }
              {count !== 0 &&
                <tr>
                  <td>インフォ・ザ・伝票 ＆ PLQ-50S（プリンター）セット</td>
                  <td className={checkoutStyles.textRight}>141,900</td>
                  <td>
                    {count}
                  </td>
                  <td className={checkoutStyles.textRight}>
                    {printerTotalPrice}
                  </td>
                </tr>
              }
            </tbody>
            <tfoot>
              <tr>
                <td className={checkoutStyles.textRight} colSpan="2">商品の小計</td>
                <td>
                  {count + count2}
                </td>
                <td className={checkoutStyles.textRight}>
                  {subTotalPrice}
                </td>
              </tr>
              <tr>
                <td className={checkoutStyles.total} colSpan="2">ご請求額</td>
                <td className={checkoutStyles.totalPrice} colSpan="2">
                  {totalPrice}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>


        {count2 !== 0 &&
          <div className={checkoutStyles.checkCartMobile}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>商品名称：</span>
                    インフォ・ザ・伝票
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>単価：</span>
                    13,750
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>数量：</span>
                    {count2}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>金額（税込）：</span>
                    {denpyoTotalPrice}
                  </td>
                </tr>
             </tbody>
           </table>
          </div>
        }

        {count !== 0 &&
          <div className={checkoutStyles.checkCartMobile}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>商品名称：</span>
                    インフォ・ザ・伝票 ＆ PLQ-50S（プリンター）セット
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>単価：</span>
                    141,900
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>数量：</span>
                    {count}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>金額（税込）：</span>
                    {printerTotalPrice}
                  </td>
                </tr>
             </tbody>
           </table>
          </div>
        }

        <div className={checkoutStyles.checkCartMobile}>
          <table>
            <tbody>
              <tr>
                <td>商品の合計</td>
              </tr>
              <tr>
                <td>
                  <span>数量：</span>
                  {count + count2}
                </td>
              </tr>
              <tr>
                <td>
                  <span>金額（税込）：</span>
                  {subTotalPrice}
                </td>
              </tr>
              <tr>
                <td className={checkoutStyles.totalPrice}>
                  ご請求額：
                  <span>
                    {totalPrice}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="loading">
          <div className="loader"></div>
          <p>送信中</p>
        </div>

        <div className={styles.shopBtnWrapper}>
          <div className={styles.backBtn}>
            <a href="/online-store/customer-form/">
              <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px"><rect fill="none" height="30" width="30"/><path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z"/></svg>
              お客様情報を修正する
            </a>
          </div>
          <button id="submitBtn" className={styles.nextBtn} onClick={order}>注文確定
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </button>
        </div>
      </div>
    </Layout>
  )
}
